import { mainAxios } from "@/mainAxios.js";
import { getAgenteAccessToken } from "../tokens.service";

export const getCampaingsList = async (requestBody) => {
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
    },
  };

    const serverResponse = await mainAxios.post(
      "/v1/campanialead/list",
      requestBody,
      config
    );
    
    return serverResponse.data
};

export const getCampaingDetails = async (id) => {
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
    },
  };

    const serverResponse = await mainAxios.get(
      "/v1/campanialead/id/"+id,
      config
    );
    
    return serverResponse.data
};

export const getCampaingLeadsList = async () => {
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
    },
  };

    const serverResponse = await mainAxios.get(
      "/v1/campania/lead-segurify/list",
      config
    );
    
    return serverResponse.data
};


export const getDisposicionesByOrigenID = async (id) => {
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
    },
  };

    const serverResponse = await mainAxios.get(
      "/v1/disposiciones/lead-segurify/list/"+id,
      config
    );
    
    return serverResponse.data
};
